import axios from 'axios'

//x获取新闻列表接口
export const getList = function (params) {
    return axios.post('/haip-biz/cms/list', params, {})
}
export const getInfoNews = function (params) {
    return axios.get('/haip-biz/cms/info?cmsId=' + params, {})
}
//数据安全落地页 创建订单
export const orderAdd = function (params) {
    return axios.post('http://api.shop.yzvet.com/api/front/landingPageOrder/add', params, {})
}
//数据安全落地页 创建订单详情
// 查看订单详情
export const landingPageOrder = function (params) {
	return axios.get('http://api.shop.yzvet.com/api/front/landingPageOrder/detail?orderNo='+params,{
	})
}
//  获取数据安全所有证书
export const dataSecurityCertificateList = function (params) {
    return axios.post('/haip-biz/dataSecurityCertificate/list', params, {})
}
//获取短信验证码
export const getSendIdentityCode = function (params) {
	return axios.post('/haip-auth-server/sms/send-ds-identity-code', params)
  }
//短信验证码验证是否准确
export const getVerifyCode = function (params) {
    return axios.post('/haip-auth-server/sms/verify-code', params)
}
